.app-header {
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  flex-shrink: 0;
  height: 60px;
  justify-content: space-between;
  padding: 1rem 7%;

  .logo {
    font-size: 1.5rem;
    font-weight: bold;
    color: #0073e6;
  }

  .user-info {
    align-items: center;
    cursor: pointer;
    display: flex;

    .user-profle {
      align-items: center;
      background: #FFF;
      border-left: 1px solid #EBEBEB;
      border-right: 1px solid #EBEBEB;
      display: flex;
      flex-shrink: 0;
      height: 56px;
      justify-content: space-between;
      padding-right: 12px;
      width: 192px;

      .data {
        display: flex;
        align-items: center;

        span {
          width: 100px;
          white-space: nowrap;
          overflow: hidden;   
          text-overflow: ellipsis;
        }
      }

      span {
        color: var(--Black-800, #27272A);
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        letter-spacing: -0.12px;
        line-height: 170%;
        margin-left: 8px;
      }
    }

    img {
      border-radius: 50%;
      width: 32px;
      height: 32px;
      margin-left: 0.5rem;
    }
  }

  .contact-btn{
    align-items: center;
    background: var(--button-bg-secondary-white, #FFF);
    border-radius: var(--border-radius-rounded-md, 6px);
    border: 1px solid var(--border-secondary-gray-300, #D1D5DB);
    font-size: 12px;
    gap: var(--spacing-1-half, 6px);
    height: var(--width-and-height-8, 32px);
    margin-right: 22px;
    padding: 5px 10px;
    padding: var(--spacing-1, 4px) var(--spacing-2-half, 10px);
  }
}

@media (width <= 768px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
  }

  .userInfo {
    margin-top: 0.5rem;
  }
}
