@tailwind base;
@tailwind components;
@tailwind utilities;
body
{
  background-color: #F0F0F0;
}
*:focus {
  outline: none !important;
  outline-offset: none !important;
  --tw-ring-inset: none !important;
  --tw-ring-offset-width: none !important;
  --tw-ring-offset-color: #fff !important;
  --tw-ring-color: none !important;
  --tw-ring-offset-shadow: none !important;
  --tw-ring-shadow: none !important;
  box-shadow: unset !important;
  border-color: none !important;
}

.case-in-progress {
  color: #F59E0B !important;
}

.case-pending-release-form {
  color: #6B7280 !important;
}

.case-needs-attention {
  color: #DC2626 !important;
}

.case-ready-for-summary {
  color: #0EA5E9 !important;
}

.case-complete {
  color: #10B981 !important;
}

p {
  color: #3D3D3D;
  /* font-family: Geist; */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}